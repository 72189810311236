export default [
    {
        name: "Не пролий а ні краплі",
        description: 'Гравці отримують по 2 кружки — одну порожню, другу з водою. Стоячи на круглій колоді один проти одного, кожний підставляє свою порожню кружку товаришу так, щоб він міг перелити в неї воду із своєї кружки і одночасно сам переливає воду зі своєї кружки в порожню кружку партнера. При цьому вода не повинна пролитися.'
    },
    {
        name: 'Старти звірят',
        description: 'Команди шикуються паралельно на відстані 5-6 кроків одна від одної. Перед ними на відстані 5-10 м (в залежності від віку) креслять лінію. У кожній команді є “заєць”, “вовк”, “пантера”, “тигр”, “лев”, “кізонька” тощо. (Ці назви дають перед початком гри кожній парі гравців). Після виклику “звірята” біжать вперед і, обігнувши прапорець, повертаються на своє місце. Хто на зворотному шляху першим перетне контрольну лінію, приносить своїй команді очко.',
    },
    {
        name: 'Останій вибуває',
        description: 'Команди розташовуються шеренгами одна навпроти другої. За сигналом діти якомога швидше біжать на протилежний бік. Той, хто перетне лінію останнім, вибуває. Біг виконується: а) з положення, стоячи обличчям до лінії; б) лежачи на животі; в) лежачи на спині тощо. Програє команда, в якої після 3-5 спроб залишиться менше гравців.',
    },
    {
        name: 'Передача сидячи',
        description: "Гравці сидять на підлозі один за одним (ноги нарізно). М'яч передається руками назад до замикаючого. Той, одержавши його, встає, біжить вперед і сідає попереду своєї колони, знову передає м'яч назад.",
    },
    {
        name: 'Швидка жаба',
        description: "Розважити дітей дошкільного віку, розвинути у них спритність, зміцнити м'язи і вестибулярний апарат допоможе естафета для дітей 5-6 років «Швидка жаба». Для її проведення не потрібно ніякого додаткового інвентарю, досить позначити лінії старту і фінішу. Команди малюків шикуються на старті. За сигналом ведучого учасники по черзі долають позначену дистанцію, стрибаючи навприсядки, як жаби. Для добре підготовлених фізично команд можна ускладнити процес: для цього зворотній шлях до старту «жаби» повинні долати задом наперед.",
    },
    {
        name: 'Сітка (велике простирдло)',
        description: 'Гра падає небо всі дітлахи збираються під простинню, на рахунок три простиня падає, кого впіймали вибуває.',
    },
    {
        name: 'Вдвох на трьох ногах',
        description: "Гравці розташовуються парами. Помічники зв'язують їм дві ноги (праву ногу одного гравця з лівою ногою другого, в одному місці — нижче колін). За сигналом вони починають біг і намагаються якомога швидше добігти до лінії фінішу і повернутись назад. Перемагає та пара, котра виконала завдання першою. Далі гру продовжує наступна пара.",
    },
    {
        name: 'Гра з надувними кульками',
        description: 'Представники команд отримують повітряні кульки і виходять на стартову лінію. Злегка підбиваючи рукою або ракеткою від бадмінтону, проходять з ними 15-20 м і заштовхують її в корзину або повертаються назад.',
    },
    {
        name: 'підріз надріз',
        description: 'Гравці стають у колону по одному на відстані витягнутої руки.Двоє ведучих беруть за кінці метровий ціпок і, нахилившись, швидко йдуть з обох боків колони обличчям до граючих, «підрізаючи» їх палицею на висоті 30 сантиметрів від землі. Гравцям треба встигнути перестрибнути через ціпок, інакше вони вибувають з гри. Одночасно із-за спин граючих теж по обидва боки колони рухаються ще двоє ведучих. Вони тримають за кінці метрову мотузку, опускаючи її на 10 сантиметрів нижче за зростання гравців. Ті, хто стоїть у колоні, повинні встигнути пригнути голови, щоб мотузка не зачепила їх, інакше вони теж вибувають з гри. Гравців стає дедалі менше. Ті, хто вибув з гри, утворять нову колону, і все починається спочатку. Гра можна ускладнити: при перескакуванні через ціпок вигукувати: «оп», а при опусканні голови говорити: «ахх» (можна свої варіанти). Усі четверо ведучих при повторенні гри замінюються новими.',
    },
    {
        name: 'Передача сидячи',
        description: "Гравці сидять на підлозі один за одним (ноги нарізно). М'яч передається руками назад до замикаючого. Той, одержавши його, встає, біжить вперед і сідає попереду своєї колони, знову передає м'яч назад.",
    },
    {
        name: 'Битви капітанів',
        description: "Для змагання потрібно поставити цебро в кінці майданчика. Тим хлопцям, що зголосилися бути капітанами, зав'язують очі шарфами та дають м'ячик. Ціль – пробігти весь шлях, опустити м'яч у кошик і повернутися. Команда, чий капітан повернувся першим і не зробив жодної помилки, перемогла.",
    },
    {
        name: 'чувачі',
        description: 'Лілимося на 2 команди, з кожного боку вирушають по людині які зустрічаються в центрі обручів при перемозі людина переходить на обруч вперед',
    },
    {
        name: 'перенеси(Кульки) ',
        description: 'На кожному стульчику лежать по 3 кульки потрібно чим швидше зібрати на свому стільчику 5 кульок',
    },
    {
        name: 'Передача в обручі сидячи',
        description: 'Відос в інсті',
    },
    {
        name: 'Сірники(4 пачки)',
        description: 'Потрібно чим швидше скласти будь-яке слово',
    },
    {
        name: 'Третій лишній(10 одіял)',
        description: 'Відеоролик',
    },
    {
        name: 'Царь просить',
        description: 'Потрібно принести загадану річ',
    },
    {
        name: "Прокататись на м`ячу ",
        description: "Розбийте (поділіть) гравців на команди по три особи на старті. Кожна команда отримує  волейбольний або футбольний м'яч. За сигналом ведучого один гравець, який підтримується під руки двома іншими гравцями, встає на м'яч і, переступаючи, котить його. Таким чином вся трійка рухається до фінішу. В  дорозі учасники почергово, не припиняючи руху, змінюють одне одного. Перемагає команда, яка найшвидше дійде до фінішу!",
    },
    {
        name: 'Літери',
        description: 'У ящику розкидано багато букв, портіно знайти букви які підходять для даного слова',
    },
    {
        name: 'Перешкоди в молитві',
        description: 'Хто більше набере води в свої відерка перемагає, наставники можуть вибивати склянки',
    },
    {
        name: 'Хрестики нули',
        description: 'Гра на швидкість, гра триватиме до 3 перемог',
    },
    {
        name: 'Стоп',
        description: "Гравці встають у коло та розраховуються по порядку номерів. Ведучий отримує маленький м'яч, виходить на середину кола, і сильно ударяє м'ячем об землю і вигукує номер.Викликаний гравець біжить за м'ячем, а решта розбігається в різні боки. Новий ведучий, схопивши м'яч, кричить «стій!». Всі зупиняють залишаючись на своїх місцях, і стоять нерухомо там, де їх застала команда стій. Ведучий намагається потрапити м'ячем у найближчого гравця, який може ухилятися, не сходячи з місця (нахилятися, присідати, підстрибувати тощо). Якщо ведучий промахнеться, він біжить за м'ячем, а решта знову розбігається. Наздогнавши м'яч, ведучий знову кричить «стій!» і кидає м'яч у когось із граючих (а вони ухиляються) доти, доки не зможе в когось потрапити. Той гравець, у якого попали м'ячем, стає новим ведучим. Гравці стають у коло і гра починається спочатку.",
    },
    {
        name: 'Навколо світу',
        description: 'Команди тримаються за руки створюючи коло потрібно не розірвавши руки перенести обруч цілий круг',
    },
    {
        name: 'Ламбада',
        description: 'Наставники тримають в натязі нилку, або палку дітлахи мають перетнути межу не впавши, виграє той хто залишиться останім',
    },
    {
        name: 'Донатело',
        description: 'Гра на вибування кожен гравесь тримає палку, стержень, в один момент вони відпускають її і мають зловити палку суперника',
    },
    {
        name: 'Гра в кальмара',
        description: 'Всі учасники стають в лінії потрібно пройти випробування, рухатись можна лише тоді коли наставник повернутий спино, якщо наставник обернувся а учасник рухався то він вибуває',
    },
    {
        name: 'Кульки через сітку',
        description: 'Чисто фан',
    },
]