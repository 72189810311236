export default [
    {
        group: 'Молодша',
        time: '11:30-12:15',
        relays: ["Чувачі (Обручі)",
                    "перенеси(Кульки) перемагає команда в якої буде 5 кульок",
                    "Передача в обручі сидячи(відос інст) тг",
                    "циліндри та кульки (відос)",
                    "Стаканчики та корзинка (відос)",
                    "Передай далі(відос)"]

    },
    {
        group: 'Середня',
        time: '12:20-13:05',
        relays: ["Чувачі",
                    "перенеси(Кульки) перемагає команда в якої буде 5 кульок",
                    "Прокататись на м'ячу",
                    "Сірники(4 пачки)",
                    "Третій лишній(10 одіял)",
                    "Царь просить"]

    },
    {
        group: 'Старша',
        time: '13:45-14:30',
        relays: ["Чувачі",
                    "перенеси(Кульки) перемагає команда в якої буде 5 кульок",
                    "Прокататись на м'ячу",
                    "Сірники(4 пачки)",
                    "Третій лишній(10 одіял)",
                    "Царь просить"]
    },
]