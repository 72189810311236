import logo from './../../../images/logo.png'
import image_1 from './../../../images/img_1.png'
import image_2 from './../../../images/img_2.png'
import image_3 from './../../../images/img_3.png'
import {GiHamburgerMenu} from "react-icons/gi"
import {AiOutlineClose} from 'react-icons/ai'
import './Header.scss'
import { useState } from 'react'
import { Link } from 'react-scroll'

export default function Header(){
    const [menuOpen, setMenuOpen] = useState(false)
    return(
        <header className="header">
            <img src={logo} alt="" />
            <div className='coach'>
                <h2>Наставники</h2>
                <div className='gallery'>
                    <div className='image-section'>
                        <img src={image_1} alt="" />
                        <p>ARtremix</p>
                    </div>
                    <div className='image-section'>
                        <img src={image_2} alt="" />
                        <p>Shum</p>
                    </div>
                    <div className='image-section'>
                        <img src={image_3} alt="" />
                        <p>Anton4ik</p>
                    </div>
                </div>
                
            </div>
            {menuOpen ? 
                <ul className='menu-list'>
                    <li className='exit-li'>
                    <AiOutlineClose className='out-button' onClick={() => setMenuOpen(false)}/>
                    </li>
                    <li>
                        <Link
                            onClick={() => setMenuOpen(false)}
                            activeClass="active"
                            to='first_element'
                            spy={true}
                            smooth={true}
                        >03.07
                        </Link>
                    </li>
                    <li>
                        <Link
                            onClick={() => setMenuOpen(false)}
                            to='second_element'
                            spy={true}
                            smooth={true}
                        >04.07
                        </Link>
                    </li>
                    <li>
                        <Link
                            onClick={() => setMenuOpen(false)}
                            to='third_element'
                            spy={true}
                            smooth={true}
                        >05.07
                        </Link>
                    </li>
                    <li>
                        <Link
                            onClick={() => setMenuOpen(false)}
                            to='fourth_element'
                            spy={true}
                            smooth={true}
                        >06.07
                        </Link>
                    </li>
                    <li>
                        <Link
                            onClick={() => setMenuOpen(false)}
                            to='fifth_element'
                            spy={true}
                            smooth={true}
                        >07.07
                        </Link>
                    </li>
                    <li>
                        <Link
                            onClick={() => setMenuOpen(false)}
                            to='button-description'
                            spy={true}
                            smooth={true}
                        >Опис вправ
                        </Link>
                    </li>
                    
                    
                </ul> : 
                <GiHamburgerMenu style={{width: '40', height: "40", cursor: 'pointer', padding: '8', backgroundColor: 'rgb(50, 50, 50)', borderRadius: '10px', zIndex: '9'}}
                
                    onClick={() => setMenuOpen(true)}/>}
            
        </header>
    )
}