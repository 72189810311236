export default [
    {
        group: 'Молодша',
        time: '11:30-12:15',
        relays: ["Алея вулиці (відос)",
                    "Захопи Стяг (Док ігри для трапорів 2.0 )",
                    "Кульки через сітку",
                    "Пірат (скріншот)",
                    "Гра в кальмара"]

    },
    {
        group: 'Середня',
        time: '12:20-13:05',
        relays: ["Алея вулиці (відос)",
                    "Захопи Стяг (Док ігри для трапорів 2.0 )",
                    "Кульки через сітку",
                    "Пірат (скріншот)",
                    "Гра в кальмара"]

    },
    {
        group: 'Старша',
        time: '13:45-14:30',
        relays: ["Алея вулиці (відос)",
                    "Захопи Стяг (Док ігри для трапорів 2.0 )",
                    "Кульки через сітку",
                    "Пірат (скріншот)",
                    "Гра в кальмара"]

    },
]