export default [
    {
        group: 'Молодша',
        time: '11:30-12:15',
        relays: ["ігра Стоп (док Спорт 2023 день 2)",
                    "Навколо світу (Ігра з обручем переадча через руки)",
                    "Лови не позіхай",
                    "Пірат (скріншот)",
                    "Естафета з обручами(відос)",
                    "Ламбада"]

    },
    {
        group: 'Середня',
        time: '12:20-13:05',
        relays: ["ігра Стоп (док Спорт 2023 день 2)",
                    "Передай футболку",
                    "донатело (палки)",
                    "Нога не рука",
                    "Лови не позіхай",
                    "Пірат (скріншот)"]

    },
    {
        group: 'Старша',
        time: '13:45-14:30',
        relays: ["ігра Стоп (док Спорт 2023 день 2)",
                    "Передай футболку",
                    "донатело (палки)",
                    "Нога не рука",
                    "Лови не позіхай",
                    "Пірат (скріншот)"]

    },
]