import './Description.scss'
import description from './../../../data/dataDescription'
import { useState } from 'react'

export default function Description(){
    const [isOpen, setIsOpen] = useState(false)
    const AllElementsDescription = description.map((item, index) => {
        return(
            <div className='question' key={index}>
                <h3>{item.name}</h3>
                <div>
                    <p>{item.description}</p>
                </div>
            </div>
        )
    })
    return(

        <div className='content-description'>
            <button id='button-description' className='button-description' onClick={() => setIsOpen(prevVlue => !prevVlue)}>Опис вправ</button>
            {isOpen ? 
                <div className="description">
                    <h2>Опис вправ</h2>
                    {AllElementsDescription}
                </div> : 
                <></>}
        </div>
    )
}