
import './App.css';
import Description from './components/TimeTable/Description/Description';
import Header from './components/TimeTable/Header/Header';
import TimeTable from './components/TimeTable/TimeTable';

function App() {
  return (
    <div className="App">
      <Header/>
      <TimeTable/>
      <Description/>

    </div>
  );
}

export default App;
