export default [
    {
        group: 'Молодша',
        time: '11:30-12:15',
        relays: ["Літери(роздрукувати)",
                    "Два обручі та палка пересування (відос)",
                    "тарілка та м'яч (зав'язки) по 5 пар",
                    "Перешкоди у молитві.",
                    "риболовля кульками (відос)",
                    "Телеграф (відос)"]

    },
    {
        group: 'Середня',
        time: '12:20-13:05',
        relays: ["Літери(роздрукувати)",
                    "Два обручі та палка пересування (відос)",
                    "тарілка та м'яч (зав'язки) по 5 пар",
                    "Перешкоди у молитві.",
                    "риболовля кульками (відос)",
                    "Хрестики нулики"]

    },
    {
        group: 'Старша',
        time: '13:45-14:30',
        relays: ["Літери(роздрукувати)",
        "Два обручі та палка пересування (відос)",
        "тарілка та м'яч (зав'язки) по 5 пар",
        "Перешкоди у молитві.",
        "риболовля кульками (відос)",
        "Хрестики нулики"]

},
]