import './TimeTable.scss';
import {BsClockHistory} from "react-icons/bs";
import {BiRadioCircleMarked} from "react-icons/bi";
import data from './../../data/dataFirstDay'
import dataSecond from "./../../data/dataSecondDay"
import dataThird from "./../../data/dataThirdDay"
import dataFourth from "./../../data/dataFourthDay"
import dataFifth from './../../data/dataFifthDay'
const arr = [2, 3, 3]
console.log(data)
const TableElementFirst = data.map((item, index) => {
    return(
        <div className='container' key={index}>
            <div className='time-section'>
                <h2>{item.group}</h2>
                <BsClockHistory style={{width:'80', height: '80', color: 'orange', margin: 'auto', marginBottom: '30'}}/>
                <div className='time'>{item.time}</div>
            </div>
            <div className='list-section'>
                {item.relays.map((relay, index) => {
                    return(
                        <div key={index} className='list-section-radio'><BiRadioCircleMarked style={{width:'18', height: '18'} } className='radio'/>{relay}</div>
                    )
                })}
            </div>
            </div>
    )
    

    // return(
    //     <div className='container' key={index}>
    //         <div className='time-section'>
    //             <h2>Молодша</h2>
    //             <BsClockHistory style={{width:'80', height: '80', color: 'orange', margin: 'auto', marginBottom: '30'}}/>
    //             <div className='time'>13:00 - 14:00</div>
    //         </div>
    //         <div className='list-section'>
    //             <div><BiRadioCircleMarked/> Не пролий а ні краплі (3 заходи)</div>
    //             <div><BiRadioCircleMarked/> Cтарти звірят</div>
    //             <div><BiRadioCircleMarked/> Останій вибуває</div>
    //             <div><BiRadioCircleMarked/> передача сидячи</div>
    //             <div><BiRadioCircleMarked/> Швидка жаба</div>
    //             <div><BiRadioCircleMarked/> Сітка (велике простирдло)</div>
    //         </div>
    //         </div>
    // )
})
const TableElementSecond = dataSecond.map((item, index) => {
    return(
        <div className='container' key={index}>
            <div className='time-section'>
                <h2>{item.group}</h2>
                <BsClockHistory style={{width:'80', height: '80', color: 'orange', margin: 'auto', marginBottom: '30'}}/>
                <div className='time'>{item.time}</div>
            </div>
            <div className='list-section'>
                {item.relays.map((relay, index) => {
                    return(
                        <div key={index} className='list-section-radio'><BiRadioCircleMarked  className='radio' style={{width:'18', height: '18'}}/>{relay}</div>
                    )
                })}
            </div>
            </div>
    )
    
})
const TableElementThird = dataThird.map((item, index) => {
    return(
        <div className='container' key={index}>
            <div className='time-section'>
                <h2>{item.group}</h2>
                <BsClockHistory style={{width:'80', height: '80', color: 'orange', margin: 'auto', marginBottom: '30'}}/>
                <div className='time'>{item.time}</div>
            </div>
            <div className='list-section'>
                {item.relays.map((relay, index) => {
                    return(
                        <div key={index} className='list-section-radio'><BiRadioCircleMarked  className='radio' style={{width:'18', height: '18'}}/>{relay}</div>
                    )
                })}
            </div>
            </div>
    )
    
})
const TableElementFourth = dataFourth.map((item, index) => {
    return(
        <div className='container' key={index}>
            <div className='time-section'>
                <h2>{item.group}</h2>
                <BsClockHistory style={{width:'80', height: '80', color: 'orange', margin: 'auto', marginBottom: '30'}}/>
                <div className='time'>{item.time}</div>
            </div>
            <div className='list-section'>
                {item.relays.map((relay, index) => {
                    return(
                        <div key={index} className='list-section-radio'><BiRadioCircleMarked  className='radio' style={{width:'18', height: '18'}}/>{relay}</div>
                    )
                })}
            </div>
            </div>
    )
    
})

const TableElementFifth= dataFifth.map((item, index) => {
    return(
        <div className='container' key={index}>
            <div className='time-section'>
                <h2>{item.group}</h2>
                <BsClockHistory style={{width:'80', height: '80', color: 'orange', margin: 'auto', marginBottom: '30'}}/>
                <div className='time'>{item.time}</div>
            </div>
            <div className='list-section'>
                {item.relays.map((relay, index) => {
                    return(
                        <div key={index} className='list-section-radio'><BiRadioCircleMarked  className='radio'/>{relay}</div>
                    )
                })}
            </div>
            </div>
    )
    
})
export default function TimeTable(){
    return(
        <>
            <div className='day-first' id='first_element'>
                03:07
            </div>
            <div className='time-table'>
                {TableElementFirst}
            </div>
            <div className='day-first' id='second_element'>
                04:07
            </div>
            <div className='time-table'>
                {TableElementSecond}
            </div>
            <div className='day-first' id='third_element'>
                05:07
            </div>
            <div className='time-table'>
                {TableElementThird}
            </div>
            <div className='day-first' id='fourth_element'>
                06:07
            </div>
            <div className='time-table'>
                {TableElementFourth}
            </div>
            <div className='day-first' id='fifth_element'>
                07:07
            </div>
            <div className='time-table'>
                {TableElementFifth}
            </div>
        </>
    )
}