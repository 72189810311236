export default [
    {
        group: 'Молодша',
        time: '11:30-12:15',
        relays: ["Не пролий а ні краплі (3 заходи)",
                    "Cтарти звірят",
                    "Останій вибуває",
                    "Передача сидячи",
                    "Швидка жаба",
                    "Сітка"]

    },
    {
        group: 'Середня',
        time: '12:20-13:05',
        relays: ["Вдвох на трьох ногах",
                    "Гра з надувними кульками",
                    "Старти звірят",
                    "підріз надріз (скакалка та палка)",
                    "Передача сидячи",
                    "Битви капітанів"]

    },
    {
        group: 'Старша',
        time: '13:45-14:30',
        relays: ["Вдвох на трьох ногах",
                    "Старти звірят",
                    "підріз надріз (скакалка та палка)",
                    "Передача сидячи",
                    "Битви капітанів"]

    },
]